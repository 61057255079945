import { Spinner } from '@ddx/component-library';
import { useEffect, useState } from 'react';
import styles from './AuthChecker.module.css';

interface IAuthCheckerProps {
  children: any;
}
export const AuthChecker: React.FC<IAuthCheckerProps> = ({ children }) => {
  const [isAuthed, setIsAuthed] = useState(false);

  useEffect(() => {
    //This could probably be moved to axios like the other calls, but it was annoying so didn't do it.
    fetch(process.env.REACT_APP_API_ENDPOINT + '/api/login/check')
      .then(async (resp) => {
        //If we get a 200 and true we are already authorized
        const tokenFound = await resp.json();
        setIsAuthed(tokenFound);
      })
      .catch((ex) => {
        //If we get an error, we aren't auth'd (should be a 302, could add a check for that), intiate real redirect call
        fetch(process.env.REACT_APP_API_ENDPOINT + '/api/login', {
          redirect: 'manual',
        }).then((x) => {
          console.log(x);
          if (x.type === 'opaqueredirect') {
            window.location.replace(x.url);
          }
        });
      });
  }, []);

  return isAuthed ? (
    <>{children}</>
  ) : (
    <div className={styles.spinner}>
      <Spinner />
    </div>
  );
};
