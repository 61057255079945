import { createContext, useContext } from 'react';
import { BackendClient } from '../clients/BackendClient';
import {
  ISubmissionsExclusionStore,
  SubmissionExclusionsStore,
} from './submissionExclusionsStore';

export interface ISubmissionsExclusionsContext {
  seStore: ISubmissionsExclusionStore;
}

const apiClient = new BackendClient(process.env.REACT_APP_API_ENDPOINT);

export const submissionsExclusionsContextDependencies: ISubmissionsExclusionsContext =
  {
    seStore: new SubmissionExclusionsStore(apiClient),
  };

export const SubmissionExclusionsContext = createContext(
  submissionsExclusionsContextDependencies
);

export const useSubmissionExclusionsContext = () =>
  useContext(SubmissionExclusionsContext);
