import { Spinner } from '@ddx/component-library';
import { useEffect, useState } from 'react';
import { useSubmissionExclusionsContext } from '../../stores/submissionExclusionsContext';
import styles from './RegionLoader.module.css';

interface IRegionLoaderProps {
  children: any;
}

export const RegionLoader: React.FC<IRegionLoaderProps> = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { seStore } = useSubmissionExclusionsContext();

  useEffect(() => {
    seStore.apiClient.getRegions().then((x) => {
      const orderedResults = x.sort((x) => -x.regionId);
      seStore.setAvailableRegions(x);
      setIsLoaded(true);
    });
  }, []);

  return isLoaded ? (
    <>{children}</>
  ) : (
    <div className={styles.spinner}>
      <Spinner />
    </div>
  );
};
