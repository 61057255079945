import { FormControl, InputLabel, makeStyles, Select } from '@material-ui/core';
import { MenuItem } from '@mui/material';
import { SearchField } from '../../clients/BackendClientGenerated';
import styles from './SearchBar.module.css';

const useStyles = makeStyles({
  select: {
    '&:before': {
      borderColor: '#4cc0ad',
    },
    '&:after': {
      borderColor: '#4cc0ad',
    },
    '&:not(.Mui-disabled):hover::before': {
      borderColor: '#4cc0ad',
    },
  },
  icon: {
    fill: 'white',
  },
  root: {
    color: 'white',
  },
});

interface ISearchDropdownMenuProps {
  selectedField: SearchField;
  onChanged: (selectedField: SearchField) => void;
}

export const SearchDropdownMenu: React.FC<ISearchDropdownMenuProps> = ({
  selectedField,
  onChanged,
}) => {
  const classes = useStyles();

  return (
    <FormControl style={{ width: '12em' }}>
      <InputLabel
        style={{ fontSize: '1.8rem', color: 'white', fontFamily: 'Gotham' }}
      >
        Search Field
      </InputLabel>
      <Select
        inputProps={{
          classes: {
            icon: classes.icon,
            root: classes.root,
          },
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        className={classes.select}
        onChange={(event) => {
          onChanged(event.target.value as SearchField);
        }}
        style={{
          fontSize: '2rem',
          color: 'white',
          height: '4rem',
          width: '10em',
          fontFamily: 'Gotham',
        }}
        value={selectedField}
      >
        <MenuItem value={SearchField.SubmissionId} className={styles.menu_item}>
          Submission ID
        </MenuItem>
        <MenuItem value={SearchField.CustomerName} className={styles.menu_item}>
          Customer Name
        </MenuItem>
        <MenuItem value={SearchField.LicenseName} className={styles.menu_item}>
          License Name
        </MenuItem>
      </Select>
    </FormControl>
  );
};
