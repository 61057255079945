import { Spinner } from '@ddx/component-library';
import { Checkbox } from '@mui/material';
import { DataGridPro, GridColDef, useGridApiRef } from '@mui/x-data-grid-pro';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import TriangleFilledAscending from '../../assets/images/TriangleFilledAscending.svg';
import TriangleFilledDescending from '../../assets/images/TriangleFilledDescending.svg';
import TriangleOutlineAscending from '../../assets/images/TriangleOutlineAscending.svg';
import {
  RelatedSubmissionsModel,
  SubmissionViewModel,
} from '../../clients/BackendClientGenerated';
import { SubmissionExclusionsContext } from '../../stores/submissionExclusionsContext';
import { ConfirmExclusionModal } from '../confirmActionModals/ConfirmExclusionModal';
import styles from './CustomDataGrid.module.css';
import { CustomGridColumnMenu } from './CustomGridColumnMenu';

export const CustomDataGrid: React.FC<{}> = observer(() => {
  const apiRef = useGridApiRef();
  const { seStore } = useContext(SubmissionExclusionsContext);

  const [confirmAddOpen, setConfirmAddOpen] = useState(false);
  const [confirmRemoveOpen, setConfirmRemoveOpen] = useState(false);

  const [relatedSubmissions, setRelatedSubmissions] =
    useState<RelatedSubmissionsModel | null>(null);

  const onConfirmAddModalClose = () => {
    setConfirmAddOpen(false);
    setRelatedSubmissions(null);
  };

  const onConfirmRemoveModalClose = () => {
    setConfirmRemoveOpen(false);
    setRelatedSubmissions(null);
  };

  const onConfirmAdd = async () => {
    await seStore.addExclusion(
      relatedSubmissions.submissionIds,
      relatedSubmissions.regionId
    );
    onConfirmAddModalClose();
  };

  const onConfirmRemove = async () => {
    await seStore.removeExclusion(relatedSubmissions.submissionIds);
    onConfirmRemoveModalClose();
  };

  const onCheckboxChanged = async (
    submission: SubmissionViewModel,
    isChecked: boolean
  ) => {
    let fetchedRelatedSubmissions: RelatedSubmissionsModel = {
      regionId: submission.regionId,
      submissionIds: [submission.submissionId],
    };

    if (!submission.isUnmatched) {
      fetchedRelatedSubmissions = await seStore.apiClient.getRelatedSubmissions(
        submission.submissionId
      );
    }

    setRelatedSubmissions(fetchedRelatedSubmissions);
    setConfirmAddOpen(false);
    setConfirmRemoveOpen(false);

    if (fetchedRelatedSubmissions.submissionIds.length > 1) {
      if (isChecked) {
        setConfirmAddOpen(true);
      } else {
        setConfirmRemoveOpen(true);
      }
    } else {
      if (isChecked) {
        seStore.addExclusion([submission.submissionId], submission.regionId);
      } else {
        seStore.removeExclusion([submission.submissionId]);
      }
    }
  };

  const timeOptions: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour12: true,
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'CST',
  };

  const columns: GridColDef[] = [
    {
      field: 'checkbox',
      headerName: 'Excluded',
      disableExport: true,
      width: 120,
      sortable: true,
      hideable: false,
      filterable: false,
      disableColumnMenu: true,
      headerClassName: styles.download_header,
      renderCell: (params) => {
        return (
          <Checkbox
            checked={params.row.isExcluded}
            disabled={
              seStore.isChangingExclusion || seStore.isSubmissionsDataLoading
            }
            onChange={async (e) => {
              await onCheckboxChanged(params.row, e.target.checked);
            }}
          />
        );
      },
    },
    {
      field: 'submissionId',
      headerName: 'Submission ID',
      headerAlign: 'left',
      width: 170,
      resizable: true,
      hideable: false,
      filterable: false,
      disableColumnMenu: true,
      sortable: true,
    },
    {
      field: 'customerName',
      headerName: 'Customer Name',
      flex: 1,
      headerAlign: 'left',
      hideable: false,
      filterable: false,
      disableColumnMenu: true,
      sortable: true,
    },
    {
      field: 'licenseName',
      headerName: 'License Name',
      flex: 1,
      headerAlign: 'left',
      hideable: false,
      filterable: false,
      disableColumnMenu: true,
      sortable: true,
    },
    {
      field: 'isUnmatched',
      headerName: 'Unmatched?',
      width: 120,
      headerAlign: 'left',
      hideable: false,
      filterable: false,
      disableColumnMenu: true,
      sortable: true,
    },
    {
      field: 'submissionReceivedAt',
      headerName: 'Submission Date Received (CST)',
      type: 'date-time',
      width: 300,
      renderCell: (params: { row: SubmissionViewModel }) => {
        return (
          <>
            {params.row.submissionReceivedAt?.toLocaleString(
              'en-US',
              timeOptions
            )}
          </>
        );
      },
      headerAlign: 'left',
      hideable: false,
      filterable: false,
      disableColumnMenu: true,
      sortable: true,
    },
    {
      field: 'excludedBy',
      headerName: 'Excluded By',
      width: 180,
      headerAlign: 'left',
      hideable: false,
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'excludedAt',
      headerName: 'Excluded At (CST)',
      type: 'date-time',
      width: 180,
      renderCell: (params: { row: SubmissionViewModel }) => {
        return (
          <>{params.row.excludedAt?.toLocaleString('en-US', timeOptions)}</>
        );
      },
      headerAlign: 'left',
      hideable: false,
      filterable: false,
      disableColumnMenu: true,
      sortable: true,
    },
  ];

  return (
    <>
      {!!relatedSubmissions && (
        <>
          <ConfirmExclusionModal
            key={'add-exclusion-modal'}
            open={confirmAddOpen}
            relatedSubmissions={relatedSubmissions}
            onClose={onConfirmAddModalClose}
            onConfirm={onConfirmAdd}
            isAddition={true}
          />
          <ConfirmExclusionModal
            key={'remove-exclusion-modal'}
            open={confirmRemoveOpen}
            relatedSubmissions={relatedSubmissions}
            onClose={onConfirmRemoveModalClose}
            onConfirm={onConfirmRemove}
            isAddition={false}
          />
        </>
      )}
      <DataGridPro
        initialState={{
          sorting: {
            sortModel: [{ field: 'submissionExclusionId', sort: 'desc' }],
          },
        }}
        loading={seStore.isSubmissionsDataLoading}
        rows={toJS(seStore.submissions) ?? []}
        getRowId={(row) => row.submissionId}
        columns={columns}
        className={styles.custom_datagrid}
        headerHeight={40}
        rowHeight={40}
        apiRef={apiRef}
        hideFooter
        disableMultipleSelection
        components={{
          ColumnSortedAscendingIcon: () => (
            <img src={TriangleFilledAscending} />
          ),
          ColumnSortedDescendingIcon: () => (
            <img src={TriangleFilledDescending} />
          ),
          ColumnUnsortedIcon: () => <img src={TriangleOutlineAscending} />,
          NoRowsOverlay: () => (
            <div className={styles.no_submissions}>No submissions</div>
          ),
          ColumnMenu: CustomGridColumnMenu,
          LoadingOverlay: () => (
            <div className={styles.spinner}>
              <Spinner size="large" />
            </div>
          ),
        }}
        componentsProps={{
          basePopper: {
            sx: {
              '&.MuiDataGrid-menu .MuiPaper-root': {
                backgroundColor: '#3b5364',
                border: 'solid black 0.5px',
              },
              '&.MuiDataGrid-menu .MuiPaper-root .MuiList-root .MuiMenuItem-root':
                {
                  backgroundColor: '#3b5364',
                  color: '#fff',
                  fontFamily: 'Gotham',
                  fontWeight: '325',
                  fontSize: '1.6rem',
                },
            },
          },
          filterPanel: {
            sx: {
              '&.MuiDataGrid-panelWrapper .MuiDataGrid-panelContent': {
                backgroundColor: '#3b5364',
                border: 'solid black 0.5px',
                color: '#fff',
              },
              '&.MuiDataGrid-panelWrapper .MuiDataGrid-panelContent .MuiDataGrid-filterForm .MuiFormControl-root .MuiInputLabel-root':
                {
                  color: '#fff',
                  fontFamily: 'Gotham',
                  fontWeight: '325',
                  fontSize: '2rem',
                },

              '&.MuiDataGrid-panelWrapper .MuiDataGrid-panelContent .MuiDataGrid-filterForm .MuiFormControl-root .MuiInput-root .MuiInput-input':
                {
                  color: '#fff',
                  fontFamily: 'Gotham',
                  fontWeight: '325',
                  fontSize: '1.6rem',
                },
              '&.MuiDataGrid-panelWrapper .MuiDataGrid-panelFooter': {
                backgroundColor: '#3b5364',
              },
              '&.MuiDataGrid-panelWrapper .MuiDataGrid-panelFooter .MuiButton-root':
                {
                  color: '#fff',
                  fontSize: '1.4rem',
                },
              '& svg': {
                color: '#fff',
              },
              '&.MuiDataGrid-panelWrapper .MuiDataGrid-panelContent .MuiDataGrid-filterForm .MuiDataGrid-filterFormOperatorInput':
                {
                  display: 'none',
                },
            },
          },
        }}
        sx={{
          '.MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '.MuiDataGrid-columnHeader': {
            backgroundColor: '#3b5364',
          },
          '.MuiDataGrid-cell': {
            border: 'none',
          },
          '& .MuiDataGrid-row:hover': {
            backgroundColor: '#3B5364',
          },
          '&.MuiDataGrid-root': {
            border: 'solid black 0px',
            boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.18)',
          },
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus': {
            outline: 'none',
          },
          '&>.MuiDataGrid-main': {
            '&>.MuiDataGrid-columnHeaders': {
              borderBottom: 'none',
            },
          },
          '&& .Mui-selected': {
            backgroundColor: '#354b5a',
          },
          '& svg': {
            color: '#fff',
          },
        }}
      />
    </>
  );
});
