import { Button } from '@ddx/component-library';
import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import { RelatedSubmissionsModel } from '../../clients/BackendClientGenerated';
import styles from './ConfirmModal.module.css';

interface IConfirmExclusionModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => Promise<void>;
  relatedSubmissions: RelatedSubmissionsModel;
  isAddition: boolean;
}

export const ConfirmExclusionModal: React.FC<IConfirmExclusionModalProps> = ({
  open,
  onClose,
  onConfirm,
  relatedSubmissions,
  isAddition,
}) => {
  const bodyText = isAddition
    ? `The parent exam contains multiple submissions. The following ${relatedSubmissions.submissionIds.length} submissions for this exam will be excluded:`
    : `The parent exam contains multiple submissions. The following ${relatedSubmissions.submissionIds.length} submissions for this exam will be unexcluded:`;

  return (
    <Dialog
      open={open}
      onClose={() => {}}
      PaperProps={{
        style: {
          backgroundColor: '#253646',
          boxShadow: 'none',
          border: '2px solid #4cc0ad',
          borderRadius: '1rem',
        },
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        className={styles.white_text}
        sx={{
          fontSize: '1.8rem',
        }}
      >
        {'Add Exclusions'}
      </DialogTitle>
      <div
        className={styles.white_text}
        style={{
          fontSize: '1.8rem',
          padding: '2em',
        }}
      >
        {bodyText}

        <ul style={{ marginLeft: '1em', marginTop: '1em' }}>
          {relatedSubmissions.submissionIds.map((x) => {
            return <li key={x}>{x}</li>;
          })}
        </ul>
      </div>

      <DialogActions>
        <Button variant="secondary" onClick={onClose}>
          CANCEL
        </Button>
        <Button onClick={onConfirm}>CONFIRM</Button>
        <div style={{ width: '0.5rem' }}></div>
      </DialogActions>
      <div style={{ height: '0.5rem' }}></div>
    </Dialog>
  );
};
