import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useRef, useState } from 'react';
import searchIcon from '../../assets/images/searchIcon.svg';
import { SearchField } from '../../clients/BackendClientGenerated';
import { useSubmissionExclusionsContext } from '../../stores/submissionExclusionsContext';
import styles from './SearchBar.module.css';

const useStyles = makeStyles((theme) => ({
  customHover: {
    '&:hover': { backgroundColor: '#3b4a59 !important' },
    '&:disabled': { opacity: '0.5' },
  },
}));

interface ISearchBarProps {
  searchField: SearchField;
}

export const SearchBar: React.FC<ISearchBarProps> = observer(
  ({ searchField }) => {
    const classes = useStyles();
    const searchBarInputRef = useRef<HTMLInputElement>(null);
    const [enteredQuery, setEnteredQuery] = useState<string>('');

    const { seStore } = useSubmissionExclusionsContext();

    const onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      submitQuery(enteredQuery);
    };

    const onChange = (event) => {
      setEnteredQuery(event.target.value);
    };

    const submitQuery = async (query: string): Promise<void> => {
      if (!seStore.isSearchDisabled) {
        seStore.searchSubmissions(query, searchField);
      }
    };

    return (
      <>
        <form onSubmit={onFormSubmit} onChange={onChange}>
          <div
            className={`${styles.searchbar} ${
              seStore.errorLoadingSubmissionData ? styles.searchbar_error : ''
            }`}
          >
            <input
              className={`${styles.searchbar_input} ${styles.noselect}`}
              placeholder="Enter Search Criteria"
              disabled={seStore.isSearchDisabled}
              ref={searchBarInputRef}
            />

            <IconButton
              disabled={seStore.isSearchDisabled}
              onClick={() => {
                submitQuery(enteredQuery);
              }}
              className={classes.customHover}
            >
              <img
                src={searchIcon}
                alt="search icon"
                className={styles.search_icon}
              />
            </IconButton>
          </div>
        </form>
      </>
    );
  }
);
