import { ThemeProvider } from '@ddx/component-library';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import App from './App';
import './globalStyles.css';

ReactDOM.render(
  <ThemeProvider>
    <BrowserRouter>
      <Route path="/" component={App} exact />
    </BrowserRouter>
  </ThemeProvider>,

  document.getElementById('root')
);
