import { BackendClientGenerated } from './BackendClientGenerated';

export class BackendClient extends BackendClientGenerated {
  constructor(
    baseUrl?: string,
    http?: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }
  ) {
    super(baseUrl, http);

    this.jsonParseReviver = (key: string, value: any) => {
      const dateTimeRegex =
        /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d)/;
      if (typeof value === 'string' && !!value && dateTimeRegex.test(value)) {
        return new Date(value + 'z');
      }

      return value;
    };
  }
}
