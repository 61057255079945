import { Button } from '@ddx/component-library';
import { FormControl, InputLabel, makeStyles, Select } from '@material-ui/core';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
} from '@mui/material';
import { useState } from 'react';
import { useSubmissionExclusionsContext } from '../../stores/submissionExclusionsContext';
import styles from './ManualEntryModal.module.css';

interface IManualEntryModalProps {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles({
  select: {
    '&:before': {
      borderColor: '#4cc0ad',
    },
    '&:after': {
      borderColor: '#4cc0ad',
    },
    '&:not(.Mui-disabled):hover::before': {
      borderColor: '#4cc0ad',
    },
  },
  icon: {
    fill: 'white',
  },
  root: {
    color: 'white',
  },
});

export const ManualEntryModal: React.FC<IManualEntryModalProps> = ({
  open,
  onClose,
}) => {
  const resetModal = () => {
    setSelectedRegionId(defaultRegionId);
    setInputSubmissionId('');
    setInputComment('');
    setSubmissionIdError(false);
  };

  const { seStore } = useSubmissionExclusionsContext();

  const [submissionIdError, setSubmissionIdError] = useState(false);

  const onConfirm = () => {
    const re = /^[a-fA-F0-9]{6,6}$/;

    if (!re.test(inputSubmissionId)) {
      setSubmissionIdError(true);
      return;
    }
    setSubmissionIdError(false);

    setIsLoading(true);
    try {
      seStore.addManualExclusion(
        [inputSubmissionId],
        selectedRegionId,
        inputComment
      );
    } finally {
      setIsLoading(false);
      resetModal();
      onClose();
    }
  };
  const classes = useStyles();

  const defaultRegionId = seStore.availableRegions.find(
    (x) => x.regionCode === 'US'
  ).regionId;

  const onSubmissionInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const input = event.target.value;

    setInputSubmissionId(input);
  };

  const [isLoading, setIsLoading] = useState(false);

  const [selectedRegionId, setSelectedRegionId] = useState(defaultRegionId);
  const [inputSubmissionId, setInputSubmissionId] = useState('');
  const [inputComment, setInputComment] = useState('');

  return (
    <Dialog
      open={open}
      onClose={() => {}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          backgroundColor: '#253646',
          boxShadow: 'none',
          border: '2px solid #4cc0ad',
          borderRadius: '1rem',
        },
      }}
    >
      <DialogTitle
        sx={{
          fontSize: '1.8rem',
          color: 'white',
          fontFamily: 'Gotham',
          width: '35rem',
          p: 4,
        }}
      >
        {'Add Exclusion'}
      </DialogTitle>
      <DialogContent>
        <Stack spacing="2em">
          <FormControl style={{ width: '12em' }}>
            <InputLabel
              style={{
                fontSize: '1.8rem',
                color: 'white',
                fontFamily: 'Gotham',
              }}
            >
              Region
            </InputLabel>
            <Select
              disabled={isLoading}
              inputProps={{
                classes: {
                  icon: classes.icon,
                  root: classes.root,
                },
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
              className={classes.select}
              onChange={(event) => {
                const regionId = event.target.value as number;
                setSelectedRegionId(regionId);
              }}
              style={{
                fontSize: '2rem',
                color: 'white',
                height: '4rem',
                width: '10em',
                fontFamily: 'Gotham',
              }}
              value={selectedRegionId}
            >
              {seStore.availableRegions.map((item) => {
                return (
                  <MenuItem
                    key={'reg-opt-' + item.regionId}
                    value={item.regionId}
                    className={styles.menu_item}
                  >
                    {item.regionCode}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <div>
            <input
              className={`${styles.input} ${
                submissionIdError ? styles.submission_input_error : ''
              }`}
              style={{ width: '100%', marginBottom: '0.5em' }}
              disabled={isLoading}
              onChange={onSubmissionInputChange}
              placeholder="Submission ID"
            ></input>
            {submissionIdError && (
              <p
                style={{
                  color: 'white',
                  fontSize: '0.7em',
                  maxWidth: '300px',
                }}
              >
                Submission ID must be 6 characters, including numbers and
                letters a-f.
              </p>
            )}
          </div>

          <input
            disabled={isLoading}
            className={styles.input}
            placeholder="Comment"
          ></input>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isLoading}
          variant="secondary"
          onClick={() => {
            resetModal();
            onClose();
          }}
        >
          CANCEL
        </Button>
        <Button
          // disabled={disableSubmit()}
          onClick={onConfirm}
        >
          ADD
        </Button>
        <div style={{ width: '0.5rem' }}></div>
      </DialogActions>
      <div style={{ height: '0.5rem' }}></div>
    </Dialog>
  );
};
