import { computed, flow, makeObservable, observable } from 'mobx';
import {
  AvailableRegionsViewModel,
  IBackendClientGenerated,
  SearchField,
  SubmissionViewModel,
} from '../clients/BackendClientGenerated';

export interface ISubmissionsExclusionStore {
  isSubmissionsDataLoading: boolean;
  isChangingExclusion: boolean;
  errorChangingExclusion: boolean;
  errorLoadingSubmissionData: boolean;
  submissions: SubmissionViewModel[];
  searchSubmissions(
    searchQuery: string,
    searchField: SearchField
  ): Generator<Promise<SubmissionViewModel[]>, void, SubmissionViewModel[]>;
  addExclusion(
    submissionIds: string[],
    regionId: number
  ): Generator<Promise<SubmissionViewModel[]>, void, SubmissionViewModel[]>;
  removeExclusion(
    submissionIds: string[]
  ): Generator<Promise<SubmissionViewModel[]>, void, SubmissionViewModel[]>;
  isSearchDisabled: boolean;
  apiClient: IBackendClientGenerated;
  addManualExclusion(
    submissionIds: string[],
    regionId: number,
    comment?: string
  ): Generator<Promise<SubmissionViewModel[]>, void, unknown>;
  availableRegions: AvailableRegionsViewModel[];
  setAvailableRegions(regions: AvailableRegionsViewModel[]): void;
}

export class SubmissionExclusionsStore implements ISubmissionsExclusionStore {
  apiClient: IBackendClientGenerated;
  isSubmissionsDataLoading: boolean = false;
  isChangingExclusion: boolean = false;
  errorChangingExclusion: boolean = false;
  errorLoadingSubmissionData: boolean = false;
  submissions: SubmissionViewModel[] = [];
  setConfirmingSubmission: SubmissionViewModel | null;
  availableRegions: AvailableRegionsViewModel[];

  constructor(apiClient: IBackendClientGenerated) {
    makeObservable(this, {
      submissions: observable,
      searchSubmissions: flow,
      isSubmissionsDataLoading: observable,
      isChangingExclusion: observable,
      errorChangingExclusion: observable,
      errorLoadingSubmissionData: observable,
      addExclusion: flow,
      addManualExclusion: flow,
      removeExclusion: flow,
      isSearchDisabled: computed,
      availableRegions: observable,
    });
    this.apiClient = apiClient;
  }

  get isSearchDisabled() {
    return this.isChangingExclusion || this.isSubmissionsDataLoading;
  }

  setAvailableRegions(regions: AvailableRegionsViewModel[]) {
    this.availableRegions = regions;
  }

  *removeExclusion(submissionIds: string[]) {
    this.isChangingExclusion = true;
    try {
      const results = (yield this.apiClient.removeSubmissions(
        submissionIds
      )) as SubmissionViewModel[];
      this.#replaceSubmissions(results);
      if (results.length == 0) {
        // Removing a manual exclusion returns nothing
        submissionIds.forEach((x) => {
          this.#setIsExcluded(x, false);
        });
      }
    } catch {
      this.errorChangingExclusion = true;
    } finally {
      this.isChangingExclusion = false;
    }
  }

  #setIsExcluded(submissionId: string, isExcluded: boolean) {
    const cpy = [...this.submissions];
    const idx = cpy.findIndex((s) => s.submissionId === submissionId);

    cpy[idx].isExcluded = isExcluded;

    if (!isExcluded) {
      cpy[idx].excludedAt = null;
      cpy[idx].excludedBy = null;
    }

    this.submissions = cpy;
  }

  #replaceSubmissions(submissions: SubmissionViewModel[]) {
    const cpy = [...this.submissions];

    submissions.forEach((res) => {
      const matchIdx = cpy.findIndex(
        (x) =>
          x.submissionId.toLowerCase() === res.submissionId.toLocaleLowerCase()
      );

      if (matchIdx > -1) {
        cpy[matchIdx] = res;
      }
    });

    this.submissions = cpy;
  }

  *addExclusion(submissionIds: string[], regionId: number) {
    this.isChangingExclusion = true;
    this.errorChangingExclusion = false;
    try {
      const results = (yield this.apiClient.addSubmissions({
        regionId: regionId,
        submissionIds: submissionIds,
        comment: '',
        isManual: false,
      })) as SubmissionViewModel[];

      this.#replaceSubmissions(results);
    } catch {
      this.errorChangingExclusion = true;
    } finally {
      this.isChangingExclusion = false;
    }
  }

  *addManualExclusion(
    submissionIds: string[],
    regionId: number,
    comment?: string
  ) {
    this.isChangingExclusion = true;
    this.errorChangingExclusion = false;
    this.isSubmissionsDataLoading = true;

    try {
      const result = (yield this.apiClient.addSubmissions({
        regionId: regionId,
        submissionIds: submissionIds,
        comment: comment,
        isManual: true,
      })) as SubmissionViewModel[];

      this.submissions = result;
    } catch {
      this.errorChangingExclusion = true;
    } finally {
      this.isChangingExclusion = false;
      this.isSubmissionsDataLoading = false;
    }
  }

  *searchSubmissions(searchQuery: string, searchField: SearchField) {
    let examData: SubmissionViewModel[] = [];
    if (this.submissions != null) {
      examData = this.submissions.slice();
    }

    this.errorLoadingSubmissionData = false;
    this.isSubmissionsDataLoading = true;

    try {
      examData = yield this.apiClient.getSubmissions(searchQuery, searchField);
      if (!examData || examData.length === 0) {
        throw new Error('No submissions found');
      }
    } catch (e) {
      console.log(e);
      this.errorLoadingSubmissionData = true;
    }

    this.submissions = examData;
    this.isSubmissionsDataLoading = false;
  }
}
