import { LogoNoSlogan } from '@ddx/component-library';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import styles from './App.module.css';
import { AuthChecker } from './components/authChecker/AuthChecker';
import { RegionLoader } from './components/regionLoader/RegionLoader';
import SubmissionsExclusionsPage from './pages/SubmissionExclusionsPage';

const App: React.FC = () => {
  LicenseInfo.setLicenseKey(
    '44bd427489b76750644fe0481072d766Tz00Mzk1NCxFPTE2ODQ0NDE1MjQ2NTcsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
  );

  return (
    <>
      <AuthChecker>
        <RegionLoader>
          <header className={styles.header}>
            <div className={styles.logobox}>
              <LogoNoSlogan />
            </div>

            <div className={styles.title_box}>
              <h3 className={styles.h3}>Submission Exclusions Portal</h3>
            </div>

            <div>
              {/* <IconButton
              rootCss={css`
                position: relative;
                bottom: -0.3rem;
                left: 0.2rem;
              `}
              iconCss={css`
                max-height: 1.8rem;
              `}
              type="logout"
              onClick={() => {}}
              color="#4cc0ad"
            /> */}
            </div>
          </header>

          <SubmissionsExclusionsPage />
        </RegionLoader>
      </AuthChecker>
    </>
  );
};
export default App;
