import {
  GridColumnMenuContainer,
  GridColumnMenuProps,
  GridFilterMenuItem,
} from '@mui/x-data-grid';
import React from 'react';

export const CustomGridColumnMenu = React.forwardRef<
  HTMLUListElement,
  GridColumnMenuProps
>(function GridColumnMenu(props: GridColumnMenuProps, ref) {
  const { hideMenu, currentColumn } = props;

  return (
    <GridColumnMenuContainer ref={ref} {...props}>
      <GridFilterMenuItem
        onClick={hideMenu}
        column={currentColumn!}
        sx={{
          '&.MuiDataGrid-menu .MuiPaper-root .MuiList-root .MuiMenuItem-root': {
            fontSize: '1.8rem',
          },
        }}
      />
    </GridColumnMenuContainer>
  );
});
