import { IconButton } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useContext, useState } from 'react';
import addIcon from '../assets/images/add.svg';
import { SearchField } from '../clients/BackendClientGenerated';
import { CustomDataGrid } from '../components/customDataGrid/CustomDataGrid';
import { ManualEntryModal } from '../components/manualEntryModal/ManualEntryModal';
import { SearchBar } from '../components/searchbar/SearchBar';
import { SearchDropdownMenu } from '../components/searchbar/SearchDropdownMenu';
import {
  SubmissionExclusionsContext,
  submissionsExclusionsContextDependencies,
} from '../stores/submissionExclusionsContext';
import styles from './SubmissionExclusionsPage.module.css';

export const SubmissionsExclusionsPage: React.FC = () => {
  const { seStore } = useContext(SubmissionExclusionsContext);

  const [selectedField, setSelectedField] = useState(SearchField.SubmissionId);
  const [showManualEntryModal, setShowManualEntryModal] = useState(false);

  return (
    <SubmissionExclusionsContext.Provider
      value={submissionsExclusionsContextDependencies}
    >
      <div className={styles.main_content}>
        <div className={styles.search_row}>
          <div>
            <SearchDropdownMenu
              selectedField={selectedField}
              onChanged={(inputField: SearchField) => {
                setSelectedField(inputField);
              }}
            />
          </div>

          <div className={styles.searchbar}>
            <SearchBar searchField={selectedField} />
          </div>
          <div>
            <IconButton
              disabled={seStore.isSearchDisabled}
              onClick={() => {
                setShowManualEntryModal(true);
              }}
            >
              <img
                alt="add icon"
                src={addIcon}
                className={styles.search_icon}
              />
            </IconButton>
          </div>
        </div>

        <div className={styles.grid_container}>
          <CustomDataGrid />
        </div>

        {seStore?.submissions?.length > 0 && (
          <div className={styles.showing_latest_row}>
            <small>
              Showing latest {seStore?.submissions?.length} submission(s).
              Search returns 100 results maximum.
            </small>
          </div>
        )}
      </div>

      <ManualEntryModal
        open={showManualEntryModal}
        onClose={() => {
          setShowManualEntryModal(false);
        }}
      />
    </SubmissionExclusionsContext.Provider>
  );
};

export default observer(SubmissionsExclusionsPage);
